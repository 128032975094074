<template>
  <div>
    <div
      v-if="rows.length == 0"
      class="bx--type-expressive-paragraph-01"
      style="padding:1rem"
    >
      Non ci sono risultati per la ricerca che hai effettuato!
    </div>
    <cv-structured-list selectable v-else>
      <template slot="items">
        <cv-structured-list-item
          v-for="(row, rowIndex) in rows"
          @click="selectToCart(row, rowIndex)"
          :key="`${rowIndex}`"
        >
          <cv-structured-list-data>
          <span></span>
            <h4 class="category">{{ $store.state.settings.packaging }} {{ $store.state.settings.qty_per_box }} {{row.cat}} </h4>
            <h2>{{ row.descr }}</h2>
            <cv-row>
              <cv-column class="text-uppercase">
                {{ row.brand }}
              </cv-column>
              <cv-column style="text-align: right;">
                {{ formatPrice(row.b2b_price_per_box) }} ({{ formatPrice(row.b2b_price) }} x pz) + IVA
              </cv-column>
            </cv-row>
          </cv-structured-list-data>
          <cv-structured-list-data>
            <AddFilled32 class="primary-fill" />
          </cv-structured-list-data>
        </cv-structured-list-item>
        <addtocart
          @close="close"
          :showModal="showModal"
          :index="index"
          :item="item"
        />
      </template>
    </cv-structured-list>
  </div>
</template>

<script>
import Modal from './Modal.vue';
import ItemQtySelector from '../ItemQtySelector/ItemQtySelector';



export default {
  name: 'ProductsList',
  components: { addtocart: Modal },
  props: {
    rows: {}
  },
  data() {
    return {
      showModal: false,
      index: '',
      item: {}
    };
  },
  mounted() {
  },
  methods: {
    selectToCart(item, index) {
      this.index = index;
      this.item = item;
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    formatPrice(price) {
        let prc = price.replace(",",".");
        return Intl.NumberFormat('it-IT', {style: 'currency', currency: this.$store.state.settings.currency}).format(prc);
    }
  },
  computed: {}
};
</script>

<style lang="scss">
@use '../../styles/theme';

.bx--structured-list-thead,
.bx--structured-list--selection
  .bx--structured-list-tbody
  .bx--structured-list-td:last-child {
  display: none;
}
.bx--structured-list-tbody .bx--structured-list-td {
  min-width: 4rem;
}
h4 {
  color: theme.$brand-01;
}
h2,
h4 {
  text-transform: capitalize;
}
svg.primary-fill {
  fill: theme.$interactive-01;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
</style>
