<template>
  <div id="app">
    <Header />
    <cv-content id="#main-content">
      <router-view />
    </cv-content>
    <footer>powered by <a href="https://www.tapecode.it" style="color: #000">Tapecode</a></footer>
</div>
</template>

<script>
import Header from './components/Header';

export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return { 
    };
  },
  mounted() {
   // this.$store.commit('clearCart');
   this.axios.defaults.headers.post['Content-Type'] =
      'application/json;charset=utf-8';
    this.axios
      .get('data/list.json')
      .then(resp => {
        this.$store.state.items = resp.data.items;
        this.$store.state.settings = resp.data.settings;
        this.$store.commit('setStatus',"loaded");
      })
      .catch(error => {
        console.log(error);
        this.$store.commit('setStatus',"error");
      });
   } 
};
</script>

<style lang="scss">

@import './styles/theme';

@import 'carbon-components/scss/globals/scss/styles';

@import './styles/type';

html {height:100%; max-width: 1000px; margin: 0 auto;}
body {height: 99%;}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex: 1 0 auto;
  }

  footer {
    text-align: center;
    font-size: 0.8rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0.5rem;
  }
}

.bx--header ~ .bx--content {
  margin-top: 5rem;
  padding-top: 0;
  position: relative;
}
</style>
