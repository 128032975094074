<template>
  <cv-row>
    <cv-column>
      <cv-button
        kind="ghost"
        tip-text="diminuisci"
        @click="decrementQty"
        :disabled="qty <= 1"
        :icon="SubtractAlt32"
      ></cv-button>
    </cv-column>
    <cv-column>
      <input
        v-model="qty"
        @change="handleQty"
        :class="`bx--text-input`"
        :style="`text-align: center; margin-top:6px;`"
        placeholder=""
      />
    </cv-column>
    <cv-column>
      <cv-button
        kind="ghost"
        :icon="AddAlt32"
        tip-text="aumenta"
        :disabled=soldOut
        @click="incrementQty"
      ></cv-button>
    </cv-column>
  </cv-row>
</template>

<script>
import AddAlt32 from '@carbon/icons-vue/es/add--alt/32';
import SubtractAlt32 from '@carbon/icons-vue/es/subtract--alt/32';

export default {
  name: 'ItemQtySelector',
  emits: ['incrementQty', 'decrementQty', 'setQty',  'updateQty'],
  data() {
    return {
      AddAlt32,
      SubtractAlt32,
      qty: 0,
    };
  },
  props: {
    index: String,
    item: {},
  },
  mounted() {
    this.qty = this.item.cart_qty;
  },
  computed: {
    soldOut() {
        return (this.item.stock_box_qty - this.item.cart_qty) - this.qty == 0;
    }
  },
  methods: {
    incrementQty() {  
        this.qty++;
        this.$emit('incrementQty');
    },
    decrementQty() {
      if (this.qty > 1) {
        this.qty--;
        this.$emit('decrementQty');
    }
      
    },
    handleQty() {
        if ('cart_qty' in this.item)
            this.updateQty();
        else
            this.setQty();
    },
    setQty() {
        this.qty = Math.min(this.item.stock_box_qty, this.qty);
        this.$emit('setQty', this.qty);
    },
    updateQty() {
        let stockQty = this.item.stock_box_qty - this.item.cart_qty;
        this.qty = Math.min(stockQty, this.qty);
        this.$emit('updateQty', {cat: this.item.cat, index: this.index, qty: this.qty});
    },
    resetQty(){
        this.qty = 0;
    }
  },
};
</script>

<style lang="scss">
.items-cart-actions {
  text-align: center;
}
input {height: 3rem;}
.cv-button svg.bx--btn__icon {width:2rem;height:2rem;}
</style>
