<template>
  <cv-header aria-label="Birra Malart">
    <cv-header-name>
      <img
        class="company--logo"
        v-bind:src="require('../../assets/logo.png')"
      />
    </cv-header-name>
    <template slot="header-global">
      <cv-header-global-action
        v-if="this.$route.path == '/'"
        @click="goToCart"
        :label="undefined"
        :tipPosition="undefined"
        :tipAlignment="undefined"
      >
        <ShoppingCart32 />
        <cv-tag
          v-if="cartItems > 0"
          :label="cartItems.toString()"
          kind="red"
          style="position:absolute; top:-0.7rem; right:-1rem;"
        ></cv-tag>
      </cv-header-global-action>
      <cv-header-global-action
        v-else
        @click="goToList"
        :label="undefined"
        :tipPosition="undefined"
        :tipAlignment="undefined"
      >
        <Receipt32 />
      </cv-header-global-action>
    </template>
  </cv-header>
</template>
<script>
import ShoppingCart32 from '@carbon/icons-vue/es/shopping--cart/32';
import Receipt32 from '@carbon/icons-vue/es/receipt/32';

export default {
  name: 'Header',
  components: { ShoppingCart32, Receipt32 },
  computed: {
    cartItems() {
      return this.$store.state.cart_qty == 0 ? '' : this.$store.state.cart_qty;
    }
  },
  methods: {
    goToCart() {
      this.$router.push('/checkout');
    },
    goToList() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
@use '../../styles/theme';

.bx--header {
  background-color: theme.$brand-01 !important;
  border: none !important;
  height: 5rem !important;
  padding: 1rem;

  .bx--header__nav {
    display: block !important;

    &::before {
      display: none !important;
    }
  }

  a.bx--header__name {padding-left: 0 !important;}
  
  .bx--header__global .bx--header__action {
    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
      background-color: theme.$brand-01;

      &::before {
        border: none;
      }
      .bx--assistive-text {
        display: none;
      }
    }
  }
}
</style>
