<template>
  <cv-modal
    :visible="showModal"
    size="xs"
    @primary-click="addToCart"
    @secondary-click="close"
    @dblclick.native="doNothing"
    @modal-hidden="close"
    @modal-shown="prepareData"
  >
    <template slot="title">Aggiungi all'ordine</template>
    <template slot="content">
      <div class="bx--row">
        <div class="bx--col">
          <p style="text-align: right;"><small v-if="stockQty > 0">Disponibilità cartoni: {{ stockQty }} pz</small><span v-else>PRODOTTO ESAURITO</span></p>
          <h4>{{ $store.state.settings.packaging }} {{ $store.state.settings.qty_per_box }} {{ item.cat }}</h4>
          <h2>{{ item.descr }}</h2>
          <div class="text-uppercase">{{ item.brand }}</div>
          <div style="text-align:right">{{ priceToCurrency(formatPrice(item.b2b_price_per_box)) }} ({{ priceToCurrency(formatPrice(item.b2b_price))}} x pz) + IVA</div>
        </div>
      </div>
      <hr />
      <qtyselector
        :item=item
        @incrementQty="incrementQty"
        @decrementQty="decrementQty"
        @setQty="setQty"
        ref="qtyselector"
      />
    </template>
    <template slot="primary-button" v-if="qty > 0">Aggiungi</template>
    <template slot="secondary-button">Annulla</template>
  </cv-modal>
</template>

<script>
import ItemQtySelector from '../ItemQtySelector/ItemQtySelector';

export default {
  emits: ['close'],
  name: 'Modal',
  components: { qtyselector: ItemQtySelector },
  props: {
    index: String,
    item: {},
    showModal: Boolean
  },
  data() {
    return {
        qty: 0,
    };
  },
  mounted() {
  },
  computed: {
    stockQty() {
        let stock_qty = this.item.stock_box_qty - this.qty;
        if ('cart_qty' in this.item)
            stock_qty -= this.item.cart_qty;
        return stock_qty;    
    },
    cartQty() {
        return "";
    },
  },
  methods: {
    incrementQty() {
        this.qty++;
    },
    decrementQty() {
      if (this.qty > 1) {
        this.qty--;
      }
    },
    setQty(qty) {
      this.qty = parseInt(qty);
    },
    close() {
      this.$emit('close');
    },
    addToCart() {
        let item = Object.assign({}, this.item);
        item.cart_qty = 0;
        item.total = 0;
        item.cart_qty = this.qty;
        if ('cart_qty' in this.item){
            item.cart_qty += this.item.cart_qty;
            this.$store.commit('decrementCartQty', this.item.cart_qty);
        }
        item.total = this.formatPrice(item.b2b_price_per_box) * item.cart_qty;
        this.$store.commit('addCartItem', { index: this.index, item: item });
        this.$emit('close');
    },
    prepareData() {   
      this.$refs.qtyselector.resetQty();
      this.qty = 0;
    },
    doNothing() {
    },
    formatPrice(price) {
        if (price === undefined) return '';
        let prc = price.replace(",",".");
        return prc;
     },
     priceToCurrency(price){
        return Intl.NumberFormat('it-IT', {style: 'currency', currency: this.$store.state.settings.currency}).format(price);
    }
  }
};
</script>

<style lang="scss">
@use '../../styles/theme';
@use 'carbon-components/scss/globals/scss/spacing' as s;

.cv-modal {
  h4 {
    margin-top: s.$spacing-05;
  }
  h2 + div {
    margin-bottom: s.$spacing-06;
  }
  hr {
    margin: s.$spacing-06 0;
    color: theme.$background;
  }
}
</style>
