<template>
  <div>
    <cv-inline-loading
      v-if="this.$store.state.status != 'loaded'"
      overlay
      small
      error-text="Al momento non è possibile caricare l'elenco dei prodotti! Riprova più tardi."
      loading-text="Caricamento prodotti in corso..."
      loaded-text="Caricamento completato"
      :state="status"
    ></cv-inline-loading>

    <cv-tabs v-else selected="0">
      <cv-search v-model="search" placeholder="Cerca qui"></cv-search>
      <cv-tab
        v-for="(value, name, index) in searchedItems"
        :key="`${index}`"
        :label="`${name}`"
      >
        <products-list :rows="value" />
      </cv-tab>
    </cv-tabs>
  </div>
</template>

<script>
import ProductsList from './ProductsList';

export default {
  name: 'ProductsPage',
  components: { ProductsList },
  data() {
    return {
      searchText: '',
      searchTimeout: null,
      onLine: navigator.onLine,
      status: 'loaded'
    };
  },
  mounted() {
  },

  computed: {
    searchedItems() {
      var $this = this;
      var ss = $this.searchText
        .toLowerCase()
        .trim()
        .split(' ');
      if (ss.length == 0) return $this.$store.state.items;
      let items = {};
       Object.keys($this.$store.state.items).forEach(function(elem){
            Object.entries($this.$store.state.items[elem]).forEach(function(val,k){
                let item = Object.assign(val[1],{});
                if (ss.every(s => Object.values(val[1]).join(' ').toLowerCase().includes(s))){
                    if (!(elem in items))
                        items[elem] = {};
                    items[elem][val[0]] = item; 
                }
            });
       });
      return items;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.searchText = val;
        }, 300);
      }
    }
  }
  
};
</script>

<style lang="scss">
@use '../../styles/theme';

a.bx--header__name:focus {
  border: none !important;
}

.bx--content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bx--structured-list {margin-bottom: 3rem;}

.bx--tabs--scrollable__nav {
  height: 50px;
  flex: 1;
}

.bx--tabs--scrollable__nav-item {
  background: theme.$layer-selected-disabled;
  outline: 0 !important;
}
.cv-tabs-button button {
  outline: 0 !important;
  border-bottom: 0 !important;
}

.cv-tabs .cv-tabs-button {
  flex: 0.5;
}
.cv-tabs .cv-tabs-button:last-child {
  padding: 0px 20px;
}

.bx--tabs--scrollable__nav-link {
  width: 100% !important;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected {
  background: theme.$field-01;
}

.bx--tab--overflow-nav-button,
.bx--tabs__overflow-indicator--right,
.bx--tabs__overflow-indicator--left {
  display: none !important;
}

.cv-search {
  padding: 30px 15px;
  background-color: theme.$field-01;
}
.bx--search-input {
  border: 0 !important;
  background: theme.$background;
}
</style>
