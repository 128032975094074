import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cart_qty: 0,
    items: {},
    status: '',
    settings: {},
    customer: "",
    customerPhone: "",
    customerDayOff: "",
  },
  mutations: {
    setStatus(state, data){
        state.status = data;
    },
    setCustomer(state, data){
        state.customer = data;
    },
    setCustomerPhone(state, data){
        state.customerPhone = data;
    },
    setCustomerDayOff(state, data){
        state.customerDayOff = data;
    },
    addCartItem(state, data) {
      state.items[data.item.cat][data.index] = data.item;
      state.cart_qty += data.item.cart_qty;
    },
    removeCartItem(state, data) {
      state.cart_qty -= state.items[data.cat][data.index].cart_qty;
      Vue.delete(state.items[data.cat][data.index], 'cart_qty');
      Vue.delete(state.items[data.cat][data.index], 'total');
    },
    incrementQty(state, data) {
      state.cart_qty++;
      state.items[data.cat][data.index].cart_qty++;
      state.items[data.cat][data.index].total = state.items[data.cat][data.index].cart_qty * state.items[data.cat][data.index].b2b_price_per_box.replace(",",".");
    },
    decrementQty(state, data) {
        state.cart_qty--;
        state.items[data.cat][data.index].cart_qty--;
        state.items[data.cat][data.index].total = state.items[data.cat][data.index].cart_qty * state.items[data.cat][data.index].b2b_price_per_box.replace(",","."); 
    },
    setQty(state, data) {
      let stock = state.cart_qty - state.items[data.cat][data.index].cart_qty;
      state.items[data.cat][data.index].cart_qty = data.qty;
      state.items[data.cat][data.index].total = state.items[data.cat][data.index].cart_qty * state.items[data.cat][data.index].b2b_price_per_box.replace(",","."); 
      state.cart_qty = stock + data.qty;      
    },
    decrementCartQty(state, qty) {
        state.cart_qty -= qty;
    },
    clearCart(state){
        state.cart_qty = 0;
        Object.keys(state.items).forEach(function(elem){
            Object.entries(state.items[elem]).forEach(function(val,k){
                let item = Object.assign(val[1],{});
                if ('cart_qty' in item){
                    Vue.delete(state.items[elem][val[0]], 'cart_qty');
                    Vue.delete(state.items[elem][val[0]], 'total');
                }
            });
       });
    },
  },
  actions: {},
  plugins: [vuexLocal.plugin],
});
